export default {
    mounted(el){
        let isDragging = false;
        let parentNode = el.parentNode;
        parentNode.style.position = 'relative';
        el.style.position = 'absolute';

        el.onmouseover = ()=>{
            el.style.cursor = 'move'
        }
        el.onmouseout = ()=>{
            el.style.cursor = 'none'
        }

        el.onmousedown = (event)=>{
            console.log('down',isDragging)
            isDragging = false;
            event = event || window.event;
            let x = event.clientX - el.offsetLeft;
            let y = event.clientY - el.offsetTop;
            
            document.onmousemove = (e)=>{
                e.preventDefault();
                isDragging = true;
                let xx = e.clientX - x;
                let yy = e.clientY - y;
                if(xx <= 0){
                    xx = 0;
                }
                if(xx >= parentNode.offsetWidth-el.offsetWidth){
                    xx = parentNode.offsetWidth - el.offsetWidth
                }
                if(yy <= 0){
                    yy = 0;
                }
                if(yy >= parentNode.offsetHeight - el.offsetHeight){
                    yy = parentNode.offsetHeight - el.offsetHeight
                }
                el.style.left = xx + 'px'
                el.style.top = yy + 'px'
            }
            document.onmouseup = () => {
                console.log('up')
                // 取消事件
                document.onmousemove = null;
                document.onmouseup = null;
                setTimeout(() => {
                    isDragging = false;
                }, 50);
              };
        }
        //解决bug，鼠标松开，任然能拖动，干掉原生的方法
        el.ondragstart = function(e){
            e.preventDefault();
        }
        el.ondragend = function(e){
            e.preventDefault();
        }

        el.addEventListener('click',(e)=>{
            if(isDragging){
                e.preventDefault();
                e.stopImmediatePropagation()
            }
        },true)

       
    },
}