import clickOutside from './clickOutside';
import drag from './drag';

const directivePlugin = {
    install: function(app){
        app.directive('clickOutside', clickOutside)
        app.directive('drag', drag)
    }
}

 export default directivePlugin